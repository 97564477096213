<script setup lang="ts">
    import { v4 as uuid4 } from 'uuid'
    import BaseLabel from '@/components/ui/forms/FormLabel.vue'
    import PhoneCountryPrefixList from '@/vars/PhoneCountryPrefixList'
    import { useField } from 'vee-validate'
    import { computed, useAttrs, watch } from 'vue'
    import { SchemaOptions } from 'yup/lib/schema'
    import BaseIcon from '../basics/BaseIcon.vue'
    import FormError from './FormError.vue'
    import MaterialIcon from '../icons/MaterialIcon.vue'

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { class: _, ...restAttrs } = useAttrs()

    type ModelValue = string | number | string[] | null

    defineOptions({
        inheritAttrs: false
    })

    const props = withDefaults(
        defineProps<{
            name?: string
            inline?: boolean
            rules?: string | Record<string, unknown> | SchemaOptions<unknown>
            type?: 'date' | 'tel' | 'text' | 'datetime-local' | string
            modelValue?: ModelValue
            error?: string
            phonePrefix?: string
            hideLabel?: boolean
            inlineLabels?: boolean
            disabled?: boolean
            icon?: string
            noBorder?: boolean
            testId?: string
        }>(),
        {
            type: 'text',
            rules: undefined,
            modelValue: undefined,
            error: '',
            phonePrefix: '+358',
            taggableKeySeparator: '',
            testId: '',
            options: () => [],
            icon: undefined
        }
    )

    const emit = defineEmits<{
        (e: 'update:modelValue', value?: ModelValue): void
        (e: 'update:phonePrefix', value: string): void
        (e: 'on-click-icon'): void
    }>()

    const { errorMessage, handleChange, handleBlur, value, meta } = useField(props.name || uuid4(), props.rules, {
        initialValue: props.modelValue,
        standalone: !props.name,
        syncVModel: true
    })

    watch(
        () => props.modelValue,
        (value) => {
            handleChange(value)
        }
    )

    const isRequired = computed(() => {
        if (!props.rules) return false

        if (typeof props.rules === 'string') {
            return props.rules.includes('required')
        }
        return Object.keys(props.rules).includes('required')
    })

    const onInputChange = (e: Event) => {
        let value: string | null = (e.target as HTMLInputElement).value
        if (props.type === 'date' && value === '') {
            value = null
        }

        handleChange(value)
        emit('update:modelValue', value)
    }

    const onBlur = () => {
        if (props.rules.includes('url') && value.value && !value.value.startsWith('http')) {
            handleChange(`https://${value.value}`)
        }

        handleBlur()
    }

    const onChangePhonePrefix = (event: Event) => {
        const selectEl = event.target as HTMLSelectElement
        const value = selectEl.value

        emit('update:phonePrefix', value)
    }
</script>

<template>
    <div
        :data-field-error="error || errorMessage || undefined"
        class="field"
        :class="{
            'field--inline': inline,
            'field--inline-labels': inlineLabels,
            'field--disabled': disabled,
            [$attrs.class as string]: true
        }"
    >
        <div class="field__inner">
            <div v-if="$slots.default || $slots.help" class="field__labels">
                <BaseLabel v-show="!hideLabel" :required="isRequired">
                    <slot></slot>
                </BaseLabel>
                <BaseLabel v-if="$slots.help" class="field__help" type="help">
                    <slot name="help"></slot>
                </BaseLabel>
            </div>
            <div class="field__input-wrapper" :class="{ 'field__input-wrapper--no-border': noBorder }">
                <div v-if="type === 'tel'" class="field__phone-prefix">
                    {{ phonePrefix }}

                    <BaseIcon legacy class="field__phone-prefix-icon" name="chevron-down" />
                    <select class="field__phone-prefix-select" @input="onChangePhonePrefix">
                        <option
                            v-for="country in PhoneCountryPrefixList"
                            :key="country.countryCode"
                            :value="country.phonePrefix"
                            :selected="phonePrefix === country.phonePrefix"
                        >
                            {{ country.countryName }} ({{ country.phonePrefix }})
                        </option>
                    </select>
                </div>

                <input
                    :id="name"
                    :data-testid="testId"
                    :name="name"
                    class="field__input"
                    :class="{
                        'field__input--phone': type === 'tel',
                        'field__input--error': error || (errorMessage && meta.touched)
                    }"
                    :type="type"
                    :value="value"
                    v-bind="restAttrs"
                    :disabled="disabled"
                    @input="onInputChange"
                    @blur="onBlur"
                />
                <MaterialIcon v-if="icon" :name="icon" class="field__icon" @click="$emit('on-click-icon')" />
            </div>
        </div>
        <div v-if="$slots.description" class="field__description text-xs">
            <slot name="description"></slot>
        </div>
        <FormError v-if="error || (errorMessage && meta.touched)">
            {{ error || errorMessage }}
        </FormError>
    </div>
</template>

<style lang="scss" scoped>
    $inputRadius: 0.25rem;
    $phonePrefixWidth: 5em;

    .field {
        $block-selector: &;
        position: relative;

        &__labels {
            margin-bottom: 0.25em;

            #{$block-selector}--inline-labels & {
                display: flex;
                align-items: baseline;
                justify-content: space-between;
            }

            #{$block-selector}--inline & {
                margin-right: 0.625em;
                margin-bottom: 0;
                flex-shrink: 0;
            }
        }

        &__description {
            width: 100%;
            margin-top: var(--scale-1r);
            color: var(--theme-color-gray--03);
        }

        &__inner {
            #{$block-selector}--inline & {
                display: flex;
                align-items: center;
            }
        }

        &__help {
            margin: 0.5em 0;
            color: var(--theme-color-gray--03);

            #{$block-selector}--inline-labels & {
                margin: 0;
            }
        }

        &__input-wrapper {
            $placeholderColor: var(--theme-color-gray--04);
            display: flex;
            align-items: center;
            color: var(--theme-text-color--secondary);
            border-radius: $inputRadius;
            border: solid 1px $placeholderColor;
            background-color: var(--theme-background-color);

            #{$block-selector}--inline & {
                flex: 1;
            }
            &--no-border {
                border: none;
            }
        }

        &__phone-prefix {
            position: relative;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.875em;
            padding: 0 0.75em;
            font-weight: bold;
            color: var(--theme-text-color);
            border-top-left-radius: $inputRadius;
            border-bottom-left-radius: $inputRadius;
            min-width: $phonePrefixWidth;
            text-align: center;
        }

        &__phone-prefix-icon {
            margin-left: 0.5em;
            font-size: 0.75em;
        }

        &__phone-prefix-select {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            max-width: $phonePrefixWidth;
        }

        &__input {
            $placeholderColor: var(--theme-color-gray--04);

            color: var(--theme-text-color);
            width: 100%;
            max-width: 100%;
            font-size: 0.875em;
            outline: none;
            border-radius: $inputRadius;
            border: none;
            padding: 0.625em;

            &:focus {
                border-color: var(--theme-text-color);
            }

            &--phone {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }

            &--error {
                border-color: var(--theme-error-color);
            }

            &::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: $placeholderColor;
                font-weight: 400;
                opacity: 1; /* Firefox */
            }

            #{$block-selector}--disabled & {
                opacity: 0.5;
                cursor: not-allowed;
                background: var(--theme-color-gray--06);
                color: var(--theme-color-gray--03);
            }

            @media (hover: hover) {
                &[type='date']::-webkit-calendar-picker-indicator,
                &[type='datetime-local']::-webkit-calendar-picker-indicator {
                    background: transparent;
                    bottom: 0;
                    color: transparent;
                    cursor: pointer;
                    height: auto;
                    left: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: auto;
                }
            }
        }

        &__icon {
            font-size: 1em;
            color: var(--theme-color-legacy);
            padding-right: var(--scale-2);
            cursor: pointer;
            max-width: 2em;
        }
    }
</style>
