import Http from './ApiClient'
import { StartApplyInformationsForm } from '@/types/forms/apply/StartApplyInformationsForm.type'
import { ResponseV3 } from '@/types/response/ResponseV3.type'
import { GetApplyInformationsResponse } from '@/types/response/apply/GetApplyInformationsResponse.type'
import { ApplyPatchProfileForm } from '@/types/forms/apply/ApplyPatchProfileForm.type'
import {
    ApplyConfigurationResponse,
    ApplyConfigurationResponseMeta
} from '@/types/response/apply/ApplyConfigurationResponse.type'
import { ApplyVIPPostResponse } from '@/types/response/apply/ApplyVIPResponse.type'
import { ApplyVipInformationForm } from '@/types/forms/apply/ApplyVipInformationForm.type'
import { ApplyVipPatchForm } from '@/types/forms/apply/ApplyVipPatchForm.type'

export default class ApplyApi {
    static async getApplyInformations(): Promise<ResponseV3<GetApplyInformationsResponse>> {
        const response = await Http.get('/v3/apply')

        return response.data
    }

    static async postApplyInformations(
        params?: StartApplyInformationsForm
    ): Promise<ResponseV3<GetApplyInformationsResponse>> {
        const response = await Http.post('/v3/apply', params)

        return response.data
    }

    static async patchProfile(
        params: ApplyPatchProfileForm
    ): Promise<ResponseV3<GetApplyInformationsResponse, { redirect?: string }>> {
        const response = await Http.patch('/v3/apply', params)

        return response.data
    }

    static async confirmGoCardlessCallback(redirectFlowId: string): Promise<void> {
        const response = await Http.get('/gocardless-confirm', {
            params: { redirectFlowId }
        })

        return response.data
    }

    static async getApplyConfig(): Promise<ResponseV3<ApplyConfigurationResponse, ApplyConfigurationResponseMeta>> {
        const response = await Http.get('/v3/apply/configuration')
        return response.data
    }

    static async renewLostApply(requestedPlan: string, frequency: string, motivations: string): Promise<unknown> {
        const response = await Http.post(`/v3/apply/lost`, {
            requestedPlan,
            frequency,
            motivations
        })

        return response.data
    }

    static async postApplyVIPInformations(params?: ApplyVipInformationForm): Promise<ResponseV3<ApplyVIPPostResponse>> {
        const response = await Http.post('/v3/apply/vip', params)

        return response.data
    }

    static async patchApplyVIP(applyId: string, params: ApplyVipPatchForm): Promise<ResponseV3<unknown>> {
        const response = await Http.patch(`/v3/apply/vip/${applyId}`, params)

        return response.data
    }
}
