import { RouteName } from '@/vars/RouteName'
import { RouteContext } from '@/types/Router.type'

export async function redirectToNewLanding({ to, next, router }: RouteContext) {
    if (to.params?.citySlug.toString() === 'dubai') {
        return router.push({ name: RouteName.CITY_LANDING, params: { citySlug: 'moyen-orient' } })
    }
    if (to.params?.citySlug.toString() === 'madrid') {
        return router.push({ name: RouteName.CITY_LANDING, params: { citySlug: 'espagne' } })
    }
    return next()
}
