<script setup lang="ts">
    import ThemeLayer from '@/components/ui/layers/ThemeLayer.vue'
    import { ThemeName } from '@/vars/ThemeAttr'
    import { useRoute } from 'vue-router'
    import { computed } from 'vue'
    import BatchHeadband from '@/components/partials/apply/leclub/banners/BatchHeadband.vue'

    type Props = {
        showBatchBanner: boolean
    }

    const theme = ThemeName.LECLUB
    const route = useRoute()

    const props = computed<Props>(() => {
        const initProps: Props = {
            showBatchBanner: false
        }
        if (route.meta?.layoutProps && typeof route.meta?.layoutProps === 'object') {
            return { ...initProps, ...route.meta?.layoutProps }
        }
        return initProps
    })
</script>

<template>
    <ThemeLayer :theme="theme">
        <div>
            <BatchHeadband v-if="props?.showBatchBanner" />
            <main>
                <RouterView />
            </main>
        </div>
    </ThemeLayer>
</template>
