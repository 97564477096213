<script setup lang="ts">
    import { onMounted, ref } from 'vue'
    import { DateTime } from 'luxon'
    import { useI18n } from 'vue-i18n'
    import { NotionRenderer } from '@notion-render/client'
    import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
    import MaterialIcon from '@/components/ui/icons/MaterialIcon.vue'
    import { ProductUpdate } from '@/types/response/configuration-api/ProductUpdate.type'
    import { formatDate } from '@/libs/helpers/dates'
    import { useMutation } from '@tanstack/vue-query'
    import ConfigurationApi from '@/libs/api/Configuration.api'
    import BaseTag from '@/components/ui/basics/BaseTag.vue'
    import MeteorsBackground from '@/components/ui/illustrations/MeteorsBackground.vue'
    import BaseButton from '@/components/ui/actions/BaseButton.v2.vue'
    import { ButtonTheme } from '@/vars/ButtonAttr'
    import ThemeLayer from '@/components/ui/layers/ThemeLayer.vue'
    import useNotification from '@/libs/compositions/useNotification'
    import { ToastType } from '@/vars/ToastAttr'
    import { useLocalStorage } from '@vueuse/core'
    import { StorageKey } from '@/vars/StorageAttr'

    const isModalVisible = defineModel<boolean>()

    const props = defineProps<{
        productUpdates: ProductUpdate[]
    }>()

    const { t } = useI18n()
    const { toast } = useNotification()
    const isBetaTestModalVisible = useLocalStorage(StorageKey.PARTICIPATE_BETA_TEST_MODAL, true)
    const htmlForProductUpdates = ref<string[]>([])

    const { mutate: participateToBeta } = useMutation({
        mutationFn: () => ConfigurationApi.participateToBetaTesting(),
        onSuccess: () => {
            isBetaTestModalVisible.value = false
            toast({
                type: ToastType.SUCCESS,
                title: t('toast_joined_beta.title'),
                text: t('toast_joined_beta.text')
            })
        }
    })

    onMounted(async () => {
        const renderer = new NotionRenderer()
        htmlForProductUpdates.value = await Promise.all(
            props.productUpdates.map((productUpdate) => renderer.render(...productUpdate.content.results))
        )
    })
</script>

<template>
    <Teleport class="modal" to="body">
        <TransitionRoot as="template" :show="isModalVisible">
            <Dialog class="relative z-[100]" @close="isModalVisible = false">
                <TransitionChild
                    as="template"
                    enter="transition-opacity ease-linear duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <div class="fixed inset-0 bg-gray-900/80" />
                </TransitionChild>

                <div class="fixed inset-0 flex">
                    <TransitionChild
                        as="template"
                        enter="transition ease-in-out duration-300 transform"
                        enter-from="-translate-x-full"
                        enter-to="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leave-from="translate-x-0"
                        leave-to="-translate-x-full"
                    >
                        <DialogPanel class="relative mr-16 flex w-full max-w-2xl flex-1 bg-white">
                            <TransitionChild
                                as="template"
                                enter="ease-in-out duration-300"
                                enter-from="opacity-0"
                                enter-to="opacity-100"
                                leave="ease-in-out duration-300"
                                leave-from="opacity-100"
                                leave-to="opacity-0"
                            >
                                <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                                    <button
                                        type="button"
                                        class="-m-2.5 p-2.5 outline-none"
                                        @click="isModalVisible = false"
                                    >
                                        <span class="sr-only">{{ t('close_sidebar') }}</span>
                                        <MaterialIcon name="close" class="title-2 mt-4 text-nuage" />
                                    </button>
                                </div>
                            </TransitionChild>

                            <div v-if="productUpdates.length" class="w-full overflow-scroll pb-[12rem]">
                                <section
                                    v-for="(productUpdate, i) in productUpdates"
                                    :key="productUpdate.id"
                                    class="mb-8r p-6"
                                >
                                    <div class="mb-2">
                                        <BaseTag class="text-sm">
                                            {{ formatDate(productUpdate.date, { format: DateTime.DATE_MED }) }}
                                        </BaseTag>
                                    </div>
                                    <h2 class="title-2 mb-6r text-legacy">{{ productUpdate.title }}</h2>
                                    <div v-safe-html="htmlForProductUpdates[i]" class="modal__html"></div>
                                    <ThemeLayer v-if="i === 0 && isBetaTestModalVisible">
                                        <div class="w-full py-6">
                                            <MeteorsBackground class="flex items-center gap-6 rounded-lg bg-legacy p-6">
                                                <MaterialIcon
                                                    class="title-4 absolute right-4 top-1 cursor-pointer p-4r text-white"
                                                    name="close"
                                                    @click="isBetaTestModalVisible = false"
                                                />
                                                <div class="flex-1">
                                                    <h3 class="title-4 mb-4r pr-8 text-white">
                                                        {{ t('give_your_opinion') }}
                                                    </h3>
                                                    <div class="flex flex-wrap items-center gap-8 text-white">
                                                        <p class="min-w-48 flex-1">{{ t('join_feedback_list') }}</p>
                                                        <BaseButton
                                                            class="ml-auto"
                                                            :theme="ButtonTheme.GHOST"
                                                            @click="participateToBeta"
                                                        >
                                                            {{ t('participate') }}
                                                        </BaseButton>
                                                    </div>
                                                </div>
                                            </MeteorsBackground>
                                        </div>
                                    </ThemeLayer>
                                </section>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </Dialog>
        </TransitionRoot>
    </Teleport>
</template>

<style lang="scss" scoped>
    .modal {
        &__html {
            display: flex;
            flex-direction: column;
            gap: var(--scale-6);
            @apply text-legacy;

            :deep(img) {
                max-width: 50%;
                margin: auto;
            }

            :deep(p) {
                @apply text-sm;
                line-height: 1.5;
            }

            :deep(a) {
                @apply underline;
            }
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "give_your_opinion": "Participez à l'évolution de Frenchfounders",
        "join_feedback_list": "En devenant beta-testeur, donnez votre avis et créons ensemble la plateforme qui correspond à vos besoins.",
        "participate": "Participer",
        "close_sidebar": "Fermer le menu",
        "toast_joined_beta": {
            "title": "Merci",
            "text": "Nous vous contacterons prochainement pour recueillir votre avis."
        }
    },
    "en": {
        "give_your_opinion": "Participate in the evolution of Frenchfounders",
        "join_feedback_list": "You would like to participate to polls, interviews or user tests to improve Frenchfounders products",
        "participate": "Participate",
        "close_sidebar": "Close sidebar",
        "toast_joined_beta": {
            "title": "Thank you",
            "text": "We will contact you soon to collect your opinion."
        }
    }
}
</i18n>
