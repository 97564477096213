import { findContext } from '@/libs/middlewares/auth'
import { EventRouteName } from '@/vars/RouteName'
import { RouteRecordRaw } from 'vue-router'
import { SpaName } from '@/vars/SpaAttr'
import { Lang } from '@/types/Lang.type'
import { SpecialEvent } from '@/vars/EventAttr'
import EmptyLayout from '@/components/layouts/EmptyLayout.vue'

const routes: RouteRecordRaw[] = [
    {
        path: '/event/:id/payment',
        name: EventRouteName.EVENT_PAYMENT,
        component: () => import('@/views/events/payment/EventPaymentPage.vue'),
        meta: {
            forceLang: (to) => {
                const EVENT_TLF_ID = SpecialEvent.TLF.toString()
                if (to.params.id === SpecialEvent.RETAIL_PARTY || to.params.id === EVENT_TLF_ID) {
                    return Lang.EN
                }
                return undefined
            }
        }
    },
    {
        path: '/networking/:slug',
        name: EventRouteName.EVENT_NETWORKING_SINGLE,
        component: () => import('@/views/events/networking-single/NetworkingSingle.vue'),
        meta: {
            context: SpaName.LENETWORK
        }
    },
    {
        path: '/event/:id/buy-tickets',
        name: EventRouteName.EVENT_PAYMENT_TUNNEL,
        component: () => import('@/views/events/ticketing/EventBuyTicketsPage.vue'),
        meta: {
            forceLang: (to) => {
                const EVENT_TLF_ID = SpecialEvent.TLF.toString()
                if (to.params.id === SpecialEvent.RETAIL_PARTY || to.params.id === EVENT_TLF_ID) {
                    return Lang.EN
                }
                return undefined
            }
        }
    },
    {
        path: '/event/:id/cancelation',
        name: EventRouteName.EVENT_ACTION_CANCELATION,
        component: () => import('@/views/events/quick-actions/CancelationPage.vue'),
        meta: {
            hideWhatsNew: true,
            layout: EmptyLayout
        }
    },
    {
        path: '/event/:id/:slug?',
        name: EventRouteName.EVENT_DETAILS,
        component: () => import('@/views/events/event-details/EventDetailsPage.vue'),
        meta: {
            context: findContext,
            forceLang: (to) => {
                const EVENT_TLF_ID = SpecialEvent.TLF.toString()
                if (to.params.id === SpecialEvent.RETAIL_PARTY || to.params.id === EVENT_TLF_ID) {
                    return Lang.EN
                }
                return undefined
            },
            hideWhatsNew: true
        }
    }
]

export default routes
